<template>
<div class="main">
  <div class="btn home" @click="page=1"><el-image :src="require(`@/views/ppt/img/3.png`)" ></el-image></div>
  <div class="btn prev" @click="prev"><el-image :src="require(`@/views/ppt/img/1.png`)" ></el-image></div>
  <div class="btn next" @click="next"><el-image :src="require(`@/views/ppt/img/2.png`)" ></el-image></div>
  <el-image :src="`http://pic.yaojian.xusos.com/${arr[page-1]}`" @click="go()"></el-image>

  <video v-if="isShow" id="homeVideo" onclick="play_v1(this)" class="home-video" controls autoplay>
        <source :src="require('@/views/ppt/img/9月22日.mp4')" type="video/mp4">
    </video>
</div>
</template>

<script>
import {pptList} from '@/api/yaojian'
export default {
  name: "index",
  data(){
    return {
      arr:[],
     page:1,
     isShow: false,
      videoPage:10,//视频页
      homePage:11   //跳转页
    }
},
mounted()  {
  if (this.$route.query.type) {
    this.page = Number(this.$route.query.type)
  }
  this.getList()
},
methods:{
    async getList(){
      let rs=await pptList()
      if(rs.code==200){
        this.arr=rs.data
      }
    },
  play_v1(v){
v.play()
},
stop_v1(v){
v.pause();
},
    go(){
      if (this.page == this.videoPage) {
        this.isShow = true
      }else if(this.page==this.homePage){
        this.$router.push("/")
      }
    },
    prev(){
      if(this.page<=1){
        this.page=1
      }else{
        this.page--
      }
      if (this.page != this.videoPage) {
        this.isShow = false
      }

    },
    next(){
      if(this.page>=this.arr.length){
        this.page=this.arr.length
      }else{
        this.page++
      }
      if (this.page != this.videoPage) {
        this.isShow = false
      }
    }
  }
}
</script>

<style scoped>
.main{position: relative;overflow: hidden;height: 100vh}
.home{
  bottom: 210px;
}
.prev{
  bottom: 120px;
}
.next{
  bottom: 30px;
}
.btn{
  user-select: none;
  width: 80px;
  right: 10px;
  z-index: 2;
  position: absolute;
}
#homeVideo {
  position: fixed;
    left: 0;
    top: 0;
}
</style>
